.successWrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  top: 0;
  left: 0;
  display: flex;
  z-index: 11;
}
.boundary {
  border-radius: 2px;
  background-color: #fff;
  width: 350px;
  height: 200px;
  margin: auto;
  color: aliceblue;
  position: relative;
  /* padding: 30px 0 0; */
  display: flex;
  flex-direction: column;
  border: #17a8e5 1px solid;
  z-index: 111;
  
}
.boundary .cross{
  position: absolute;
  color: #17a8e5;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #17a8e5 1px solid;
}
.boundary .cross:hover{
  background-color: #17a8e5;
  color: #fff;
}
.upperBox {
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  overflow: auto;
}
.upperBox .message{
  color: #222222;
  font-size: 15px;
  padding: 10px;
  flex: 1;
  text-wrap: wrap;
  overflow: auto;
}

