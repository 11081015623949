.logoHeadBox {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 9;
  justify-content: space-between;
  margin-right: 5px;
}
.logoHeadBox .logoBx {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoHeadBox .boqVersion {
  padding: 6px;
  border: 0.5px solid #b0b0b0;
  border-radius: 2px;
  width: 100%;
  height: 35px;
  position: relative;
}
/* .logoHeadBox .boqVersion .select {
  transform-origin: bottom;
} */
.logoHeadBox .logoBx ~ .logoBx {
  border-left: 0.5px solid #b0b0b0;
}
.leftLogos {
  display: flex;
  align-items: center;
  gap: 5px;
}
.bg_col {
  border-radius: 2px;
  background-color: #f3fcff;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 15px;
}
.login_signUp {
  padding: 7px;
  position: absolute;
  right: 15px;
  top: 48px;
  background-color: #dfe4e8;
  border: 1px solid rgba(204, 204, 204, 0.25);
  border-radius: 2px;
  width: 150px;
  transition: 0.3s;
  box-shadow: 0 4px 15px 0 grey;
}
.login_signUp:hover {
  border: 1px solid #17a8e5;
}
.login_signUp li {
  padding: 0px 15px;
  cursor: pointer;
  line-height: 35px;
  transition: 0.3s;
}
.login_signUp li.selected {
  background-color: #17a8e5;
  border-radius: 2px;
}
.login_signUp li:hover {
  transition: 0.3s;
  background-color: #17a8e5;
  border-radius: 2px;
  color: #fff;
}
.boqVersionBox {
  border: 1px solid #b0b0b0;
  padding: 8px;
  margin-right: 10px;
  width: 100px;
  text-align: center;
  /* background-color: #f3fcff; */
  /* border-radius: 5px; */
}
.roomName {
  width: fit-content;
  /* height: 50px; */
  color: #000000;
  font-size: 12px;
  /* border-left: 0.5px solid #b0b0b0; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-left: 5px;
  font-weight: 600;
  text-transform: capitalize;
  height: 15px;
}
.incoId{width: fit-content;
  /* height: 50px; */
  color: #666666;
  font-size: 10px;
  /* border-left: 0.5px solid #b0b0b0; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-left: 5px;
  height: 15px;
  font-weight: 400;}
.labels {
  width: fit-content;
  color: #000000;
  font-size: 10px;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-left: 5px;
  font-weight: 600;
  background: #DAF4FF;
  border-radius: 2px;
}

.cloudSync {
  background-color: #f3fcff;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-right: 10px;
}
.syncStatus{
  font-size: 12px;
  color: #666666;
  margin-right: 15px;
  font-style: italic;
}
.errorList{height: 35px;width: 35px;display: flex;align-items: center;justify-content: center;background-color: #FFD2D2;border-radius: 2px;cursor: pointer;margin-right: 10px;} 
.errorListDis{height: 35px;width: 35px;display: flex;align-items: center;justify-content: center;background-color: #E5E5E5;border-radius: 2px;cursor: not-allowed;margin-right: 10px;} 