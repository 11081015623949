@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
*,
::before,
::after {
  box-sizing: border-box;
  outline: none;
}
body {
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
html {
  scroll-behavior: smooth;
}
body,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
strong {
  padding: 0;
  margin: 0;
  list-style: none;
}
body,
p {
  font-size: 14px;
  color: #666;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
a,
.btn,
button,
input,
select {
  text-decoration: none;
  outline: none;
  border: none;
}
.img-responsive {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: absolute;
  z-index: 10000;
}
::-webkit-scrollbar-thumb {
  background-color: #17a8e5;
  border-radius: 20px;
}
::-moz-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  z-index: 10000;
  right: 0;
}
::-moz-scrollbar-thumb {
  background-color: #17a8e5;
  border-radius: 20px;
}
.font20 {
  font-size: 20px;
}
.font18 {
  font-size: 18px;
}
.font16 {
  font-size: 16px;
}
.font14 {
  font-size: 14px;
}
.font12 {
  font-size: 12px;
}
.font10 {
  font-size: 10px;
}
.font8 {
  font-size: 8px;
}
.text666 {
  color: #666;
}
.text222 {
  color: #222;
}
.textfff {
  color: #fff;
}
.text000 {
  color: #000;
}
.text333 {
  color: #333;
}
.textred {
  color: red;
}
.textblue {
  color: #2b75cb;
}

.bgWhite {
  background: #fff;
}
.bgPrimary{background-color: #17a8e5;}
.bgSecondary{background-color: #AA6BDC;}
.overFlowHidden {
  overflow: hidden;
}
.lineHeight16 {
  line-height: 16px;
}
.lineHeight20 {
  line-height: 20px;
}
.lineHeight24 {
  line-height: 24px;
}
.lineHeight26 {
  line-height: 26px;
}

.mb46 {
  margin-bottom: 46px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb5 {
  margin-bottom: 5px;
}
.mt12 {
  margin-top: 12px;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.ml5 {
  margin-left: 5px;
}

.pb16 {
  padding-bottom: 16px;
}
.pb12 {
  padding-bottom: 12px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb5 {
  padding-bottom: 5px;
}
.pt12 {
  padding-top: 12px;
}
.pl5 {
  padding-left: 5px;
}
.p5 {
  padding: 5px;
}

.f600 {
  font-weight: 600;
}
.fbold {
  font-weight: bold;
}
.fadeOut {
  filter: opacity(0.5);
}
.fadeOut img {
  filter: opacity(0.5);
}
.textCntr {
  text-align: center;
}
.dispBlok {
  display: block;
}
.dispAppr {
  display: none;
}
.dFlex {
  display: flex;
}
.flexCol{flex-direction: column;}
.alignCenter {
  align-items: center;
}
.r90{transform: rotate(90deg);}
.justBet{justify-content: space-between;}
.capital{text-transform: capitalize;}
.gap30{gap: 30px;}
.gap15{gap: 15px;}
.gap10{gap: 10px;}
.f500{font-weight: 500;}
.textCenter{text-align: center;}
.textRight{text-align: right;}
.disabled{cursor: not-allowed !important ;background-color: #e5e5e5  !important; color: #666666 !important;border: none !important;}

.pointer{cursor: pointer;}
.cMove{cursor: move;}
.overlayLoader {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgb(255 255 255 / 70%);
  z-index: 25;
  flex-direction: column;
  top: 0;
  left: 0;
}


/* Excel table quill styling */

.ql-toolbar{
  background-color: #17a8e5;
  z-index: 999;
}
.ql-bubble .ql-tooltip{
  transform: none !important;
  color: #000 !important;
}
.ql-tooltip-arrow{display: none !important;}

.incopdf2Minbox {
  padding: 20px;
  background: #daf4ff;
  height: 49.6rem;
  width: 70.157rem;
}
.incopdf2Minbox .incopdfInerm {
  padding: 20px;
  border: 1px solid #17a8e5;
  border-radius: 2px;
  background: #fff;
}
.incopdf2Minbox .incoHeadm {
  padding: 10px;
  background: #daf4ff;
  border-radius: 2px;
}
.incopdf2Minbox .incopdfImbox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  gap: 10px;
  position: relative;
}
.incopdf2Minbox .inpdfInimgb figure img {
  width: 440px;
}
.incopdf2Minbox .incoLfttiles {
  padding-right: 16px;
  border-right: 1px solid #b0b0b0;
}
.incopdf2Minbox .incoLftlowe {
  display: flex;
  align-items: center;
  gap: 16px;
}
.inPloweinBt {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  position: absolute;
  bottom: -10px;
  right: 7px;
}
.incoBtiles figure img {
  padding: 8px 10px;
  background: #eeeeee;
  border-radius: 2px;
}
.incopdf2Minbox .incoPlowehwd {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f6f6f6;
  border-radius: 2px;
}
.incopdf2Minbox .incopdrifSp {
  padding: 8px 10px;
  background: #fff;
  border-radius: 2px;
}

/* pdf 1 start */
.incoMainfistbox {
  padding: 30px;
  background: #daf4ff;
}
.incoFirstiner {
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #17a8e5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.incoFistseconbox {
  position: relative;
  height: 690px;
  width: 100%;
}
.incoFistseconbox figure img {
  height: 690px;
  width: 100%;
}
.incopdIntertextbox {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 38%;
}
.pdgFinertext {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  border-top: 1px solid #1c69a0;
}
.widTh200 {
  width: 200px;
}
.pdfInlogo {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.inPloweinBt {
  bottom: 20px;
  right: 20px;
}
/* pdf 1 End */

/* pdf 2 start */
.incoTwopdmain {
  position: relative;
  height: 590px;
}
.inPdsvimgnox img {
  border-radius: 2px;
  width: 80px;
  height: 80px;
}
.incopdfImbox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  gap: 10px;
}
.incopdfImbox .inpdfInimgb figure img {
  width: 510px;
  height: 255px !important;
  object-fit: contain;
  border-radius: 2px;
  border: 1px solid;
}
/* pdf 2 End */

/* pdf 3 start */
.incoPdthrmbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  height: 590px;
  flex-direction: column;
  /* gap: 15px; */
}
.incoPdthrmbox .incoodTinbox {
  width: 100%;
}
.incoPdthrmbox .incoodTinbox .table-container{
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
}
.incoPdthrmbox .incoodTinbox figure img {
  width: 100%;
  max-height: 550px;
  position: relative !important;
}
.incoPdthrmbox .incoodFloor figure img {
  width: 100%;
  max-height: 550px;
}
.incoPdthrmbox .incoodTinbox table{
  width: 50%;
  border-collapse: collapse;
  border: 1px solid #17a8e5;
}
.incoPdthrmbox .incoodTinbox table td{
  padding: 5px 10px;
  font-size: 15px;
  color: #222;
  padding-left: 10px;
  border-bottom: 1px solid #17a8e5;
  border-right: 1px solid #17a8e5;
}
.incoPdthrmbox .incoodTinbox table tr:last-child td {
  border-bottom: none;
}
.viewTitle{
  /* background-color: #daf4ff; */
  padding: 5px;
  font-size: small;
  border-radius: 2px 2px 0px 0px;
  z-index: 1;
}
/* pdf 3 End */

/* pdf 4 start */
.incoPdfourmbox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 590px;
}
.incoPdfourmbox .infoFourinner figure img {
  width: 100%;
}

/* pdf 4 End */

/* pdf 5 start */
.inFivpdfmbox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 590px;
}
.inFivpdfmbox .incoFivinerbox figure img {
  width: 100%;
}
/* pdf 5 End */

/* pdf 6 start */
.incoSixmBox {
  position: relative;
  height: 590px;
}
/* .incoSixmainubox{position: relative;height: 590px;} */
.incoSixmBox .incoPdsixHead {
  padding: 6px 10px;
  border-radius: 2px;
  background: #f6f6f6;
  display: flex;
  align-items: center;
}
.incoSixmBox .incoPdsixHead span {
  padding-left: 10px;
  border-left: 1px solid #b0b0b0;
  width: 20%;
}
.incoSixmBox .incoPdsixHead span:nth-child(2) {
  width: 25%;
}
.incoSixmBox .incoPdsixHead span:nth-child(5) {
  width: 15%;
}
.incoSixmBox .incoPdsixHead span:first-child {
  border: none;
  padding: 0;
}
.incoCsixinntiles .interPdftexsp {
  font-size: 8px;
  padding: 5px;
  border-radius: 2px;
  background: #fff;
  line-height: 8px;
  display: block;
}
.innerTextbox .ineerTexboxan {
  font-size: 8px;
  color: #000;
  display: block;
  line-height: 12px;
}
.incoCsixcabtiles .incoCsixinntiles {
  padding: 5px;
  background: #f6f6f6;
  border-radius: 2px;
  display: flex;
  gap: 5px;
  width: 20%;
  height: -moz-fit-content;
  height: fit-content;
}
.incoCsixcabtiles {
  padding: 10px 5px;
  display: flex;
  /* gap: 20px; */
  border-bottom: 1px dashed #b0b0b0;
  /* white-space: nowrap; */
}
.incoRgtxttile {
  padding: 5px;
  width: 20%;
}
.accpdfTextiles {
  padding: 5px;
  width: 20%;
  max-width: 20%;
}
.incoRgtxttile .innerTextrgtbox {
  display: flex;
  gap: 40px;
}
.incoRgtxttile .innerTextrgtbox span {
  font-size: 9px;
  color: #666;
  line-height: 15px;
  display: block;
  width: 80px;
}
.incoRgtxttile .innerTextrgtbox p {
  font-size: 9px;
  color: #222;
  line-height: 12px;
}
.accpdfTextiles .accpdgInnweText{
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: flex-start;
}
.accpdfTextiles .accpdgInnweText span {
  font-size: 9px;
  line-height: 15px;
  color: #000;
}
.accpdfTextiles .accpdgInnweText p {
  font-size: 9px;
  line-height: 15px;
  color: #666;
  margin-bottom: 5px;
}

.watermark{width: 1045px;height: 590px;position: absolute;top: 0;left: 0;background-color: #F1F1F1;opacity: 0.8;z-index: 11;color: red;display: flex;align-items: center;justify-content: center;font-size: 30px;font-weight: 400;}
/* pdf 6 End */
/* .dropDown::after {
  content: "";
  pointer-events: none;
  position: absolute;
  right: 10%;
  top: 45%;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23222222' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
    no-repeat;
  width: 12px;
  height: 7px;
} */
/* pdf 7 start */
.incoSvenmainbox {
  position: relative;
  height: 590px;
}
.incoSeninermain {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
}
.incoSvenmainbox .incoIntiles {
  padding: 10px;
  border-radius: 2px;
  background: #f6f6f6;
  min-height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.incoIntiles .inBasicdetext {
  padding: 5px 0;
  display: flex;
  border-top: 1px solid #b0b0b0;
}
.incoIntiles .inBasicdetext span {
  font-size: 10px;
  color: #666;
}
.incoIntiles .inBasicdetext p {
  font-size: 10px;
  color: #222;
  white-space: nowrap;
}
.widTh100 {
  width: 97px;
}
/* pdf 7 End */

/* pdf 8 start */
.incoEpdfmianbox {
  position: relative;
  height: 590px;
}
.inEigtaccmainbox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  gap: 10px;
}
.inEigaccinertiles {
  padding: 10px;
  border-radius: 2px;
  background: #f6f6f6;
}
.inEigaccinertile {
  padding: 10px;
  border-radius: 2px;
  /* background: #f6f6f6; */
}
.incoInpdimgtexbo {
  display: flex;
  gap: 10px;
}
.incoEigttextbox {
  width: 100%;
}
.imgtexbox img {
  width: 40px;
  height: 40px;
  border-radius: 2px;
}
.incoInpdimgtexbo .incoEigttextbox table {
  width: 100%;
  border-collapse: collapse;
}
.incoInpdimgtexbo .incoEigttextbox table th {
  padding: 5px 10px;
  text-align: left;
  font-size: 10px;
  color: #222;
  background: #fff;
  border-collapse: collapse;
}
.incoInpdimgtexbo .incoEigttextbox table th span {
  border-left: 1px solid #b0b0b0;
  padding-left: 10px;
}
.incoInpdimgtexbo .incoEigttextbox table th:first-child span {
  border-left: none;
}
.incoInpdimgtexbo .incoEigttextbox table th:first-child {
  border-radius: 2px 0 0 2px;
}
.incoInpdimgtexbo .incoEigttextbox table th:last-child {
  border-radius: 0 2px 2px 0;
}
.incoInpdimgtexbo .incoEigttextbox table td {
  padding: 5px 10px;
  font-size: 10px;
  color: #222;
  padding-left: 10px;
  border-bottom: 1px solid #b0b0b0;
}
.incoInpdimgtexbo .incoEigttextbox table td {
  padding: 5px 10px;
  font-size: 10px;
  color: #222;
  padding-left: 10px;
  border-bottom: 1px solid #b0b0b0;
}
.incoInpdimgtexbo .incoEigttextbox table tr:last-child td {
  border-bottom: none;
}
.incoInpdimgtexbo .incoEigttextbox table td span {
  padding-left: 10px;
}
.incoEightimgbox img {
  width: 60px;
  height: 60px;
  border-radius: 2px;
}
/* pdf 8 End */

.canvas {
  position: relative;
  z-index: 2;
}
.canvas2 img {
  width: 100%;
  height: 100%;
  padding-bottom: 13px;
}
/* .pdfSectionBox{padding: 119px 296px 74px 232px;display: flex;flex-direction: column;gap: 24px;} */
.pdfSectionBox {
  position: relative;
  /* overflow: hidden; */
  max-width: calc(-422px + 100vw);
  padding: 121px 0px 4px;
  left: 180px;
  overflow: auto;
  border-left: 0.5px solid #b0b0b0;
}
.canvas2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding: auto;
}
.whiteNrp {
  white-space: nowrap;
}
.downloadButton button{
  padding: 10px;
  background-color: #17a8e5;
  position: absolute;
  top: 100px;
  right: 20px;
  cursor: pointer;
  color: #daf4ff;
}
.react-transform-wrapper {
   width: 100% !important;
   height: 100% !important;
}
.react-transform-component {
   width: 100% !important;
   height: 100% !important;
   display: block !important;
}

/* Text Editor quill styling */
.ql-size-small {
  font-size: 0.5em;
}

.ql-size-large {
  font-size: 1.5em; 
}

.ql-size-huge {
  font-size: 2.5em; 
}

.active .ql-toolbar.ql-snow {
  display: block;
}

.none .ql-toolbar.ql-snow {
  display: none;
}

.ql-editor {
  overflow: hidden!important;
  overflow-y: hidden!important;
  line-height: normal!important;
  padding: 0px!important;
}

.ql-toolbar {
  min-width: 305px; 
  position: fixed;
  margin-top: -100px;
  margin-left: -70px;
  z-index: 999;
  /* transform: translate(-70px, -102px); */

}

.ql-toolbar.ql-snow{
  background-color: #17a8e5;
}

 .dangerText:hover{
  outline: 1px solid rgb(68, 135, 207);
  cursor: -webkit-grab;
  cursor: grab;
} 
.dangerText p{
  color: black;
}
.ql-container{
  font-family: roboto!important;
}
.ql-container.ql-snow{
  border: none!important;
} 

#textbox{
  transform: unset!important;
}

.rotate-vertical .ql-toolbar.ql-snow{
  transform: rotate(-90deg);
  margin-left: -170px;
}

.rotate-180 .ql-toolbar.ql-snow{
  transform: rotate(180deg);
}

.rotate-270 .ql-toolbar.ql-snow{
  transform: rotate(270deg);
  top: 30px;
}

.rotate-90 .ql-toolbar.ql-snow{
  transform: rotate(90deg);
  margin-left: 10px;
}


.rotate-180minus .ql-toolbar.ql-snow{
  transform: rotate(-180deg);
  margin-left: 55px;
  margin-top: 7px; 
}

/* Content */

/* AddonsTable.css */

.addons-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.addons-section {
  background: white;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

.section-title {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

.table-wrapper {
  overflow-x: auto;
  width: 100%;
}

.addons-table {
  width: 100%;
  table-layout: fixed; /* Ensures columns have fixed width */
  border-collapse: collapse;
  text-align: left;
  text-wrap: wrap;
}

.addons-table th, .addons-table td {
  padding: 12px 16px;
  font-size: 10px;
  border-bottom: 1px solid #e5e7eb;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Specific widths for each column */
.addons-table th:nth-child(1),
.addons-table td:nth-child(1) {
  width: 7%; /* Adjust as needed */
}

.addons-table th:nth-child(2),
.addons-table td:nth-child(2) {
  width: 40%; /* Adjust as needed */
}

.addons-table th:nth-child(3),
.addons-table td:nth-child(3) {
  width: 15%; /* Adjust as needed */
}

.addons-table th:nth-child(4),
.addons-table td:nth-child(4) {
  width: 15%; /* Adjust as needed */
}

.addons-table th:nth-child(5),
.addons-table td:nth-child(5) {
  width: 10%; /* Adjust as needed */
}

.addons-table th:nth-child(6),
.addons-table td:nth-child(6) {
  width: 30%; /* Adjust as needed */
  text-wrap: wrap;
}

.addons-table th:nth-child(7),
.addons-table td:nth-child(7) {
  width: 10%; /* Adjust as needed */
}

.addons-table th {
  background-color: #f9fafb;
  font-weight: 400;
  color: #4b5563;
}

.item-name {
  display: flex;
  align-items: center;
  gap: 12px;
}

.item-image {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  background-color: #f3f4f6;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder-image img {
  width: 32px;
  height: 32px;
}

.item-title {
  font-weight: 500;
  white-space: wrap;
}

.description-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.description-item {
  font-size: 10px;
  line-height: 1.25;
}

.description-key {
  color: #6b7280;
  margin-right: 4px;
}

.description-value {
  color: #111827;
}



.no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.pointerNone {
  pointer-events: none;
}


/* Image Cropper */

.ImageCropperWrapper {
  position: absolute;
  z-index: 1000;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.cropper-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Customize the cropper appearance */
.reactEasyCrop_Container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #333;
}

.reactEasyCrop_CropArea {
  border: 2px solid #00ff00;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
}
.Select_dropDown__Q8yXJ {
  min-width: 125px;
  height: 100%;
}
.Select_dropDown__Q8yXJ::after {
  top: 18px;
}
.Select_dropDown__Q8yXJ .Select_dropdownchnRbox__w461a {
  padding-left: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  text-transform: capitalize;
}

.Select_changEromin__PDpW8 {
  box-shadow: 0 5px 15px 0 rgba(119, 119, 119, 0.25);
  border-radius: 2px;
  overflow: auto;
  position: absolute;
  top: 40px;
  left: -2px;
  display: none;
  max-height: 40vh;
  height: -moz-fit-content;
  height: fit-content;
}
.Select_dropDown__Q8yXJ.Select_active__YLUeu .Select_changEromin__PDpW8 {
  display: block;
}
.Select_changRoomtilesModular__lBSki:hover {
  background: #daf4ff;
}
.Select_changRoomtilesModular__lBSki {
  padding: 10px;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  width: 150px;
  gap: 16px;
  background: #fff;
  border-bottom: 1px solid #b0b0b0;
  justify-content: space-between;
  min-width: 150px;
}
.Select_changRoomtilesModular__lBSki.Select_active__YLUeu {
  background: #17a8e5;
  color: #fff;
}
.Select_changRoomtilesModular__lBSki span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Select_dropDownDownArrow__ixDYz {
  content: "";
  pointer-events: none;
  position: absolute;
  right: 10%;
  top: 45%;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23222222' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
    no-repeat;
  width: 12px;
  height: 7px;
}

.Select_dropDownUpArrow__OiNV4 {
  content: "";
  pointer-events: none;
  position: absolute;
  right: 10%; /* Adjust as needed */
  top: 45%; /* Adjust as needed */
  background: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5L5 1L9 5' stroke='%23222222' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat;
  width: 12px; /* Adjust as needed */
  height: 6px; /* Adjust as needed */
}

/* version */
.Select_changRoomtilesVersion__b569R{
  padding: 10px;
  display: flex;
  align-items: center;
  width: 200px;
  gap: 16px;
  background: #fff;
  border-bottom: 1px solid #b0b0b0;
  justify-content: space-between;
  min-width: 150px;
  color: #000;
  height: 38px;

}
.Select_changRoomtilesVersion__b569R:hover {
  background: #daf4ff;
}
.Select_changRoomtilesVersion__b569R .Select_innerText__cXYBt{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
}
.Select_changRoomtilesVersion__b569R.Select_active__YLUeu {
  color: #17a8e5;
  font-weight: 600;
}
.Select_changRoomtilesVersion__b569R span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Select_boqLink__X7uJn{
  width: 20px;
  height: 20px;
  background-color: #daf4ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.Select_changRoomtilesVersion__b569R:hover .Select_boqLink__X7uJn {
  background-color: #fff;
}
.GlobalError_successWrapper__B0ZR6 {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  top: 0;
  left: 0;
  display: flex;
  z-index: 11;
}
.GlobalError_boundary__Y51ka {
  border-radius: 2px;
  background-color: #fff;
  width: 350px;
  height: 200px;
  margin: auto;
  color: aliceblue;
  position: relative;
  /* padding: 30px 0 0; */
  display: flex;
  flex-direction: column;
  border: #17a8e5 1px solid;
  z-index: 111;
  
}
.GlobalError_boundary__Y51ka .GlobalError_cross__S5Ngv{
  position: absolute;
  color: #17a8e5;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #17a8e5 1px solid;
}
.GlobalError_boundary__Y51ka .GlobalError_cross__S5Ngv:hover{
  background-color: #17a8e5;
  color: #fff;
}
.GlobalError_upperBox__oy8aM {
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  overflow: auto;
}
.GlobalError_upperBox__oy8aM .GlobalError_message__9tPmo{
  color: #222222;
  font-size: 15px;
  padding: 10px;
  flex: 1 1;
  text-wrap: wrap;
  overflow: auto;
}


.Header_logoHeadBox__JS5Wl {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 9;
  justify-content: space-between;
  margin-right: 5px;
}
.Header_logoHeadBox__JS5Wl .Header_logoBx__Yka7b {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Header_logoHeadBox__JS5Wl .Header_boqVersion__Xv_Mb {
  padding: 6px;
  border: 0.5px solid #b0b0b0;
  border-radius: 2px;
  width: 100%;
  height: 35px;
  position: relative;
}
/* .logoHeadBox .boqVersion .select {
  transform-origin: bottom;
} */
.Header_logoHeadBox__JS5Wl .Header_logoBx__Yka7b ~ .Header_logoBx__Yka7b {
  border-left: 0.5px solid #b0b0b0;
}
.Header_leftLogos__wTXy0 {
  display: flex;
  align-items: center;
  gap: 5px;
}
.Header_bg_col__2trGS {
  border-radius: 2px;
  background-color: #f3fcff;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 15px;
}
.Header_login_signUp__5X7tk {
  padding: 7px;
  position: absolute;
  right: 15px;
  top: 48px;
  background-color: #dfe4e8;
  border: 1px solid rgba(204, 204, 204, 0.25);
  border-radius: 2px;
  width: 150px;
  transition: 0.3s;
  box-shadow: 0 4px 15px 0 grey;
}
.Header_login_signUp__5X7tk:hover {
  border: 1px solid #17a8e5;
}
.Header_login_signUp__5X7tk li {
  padding: 0px 15px;
  cursor: pointer;
  line-height: 35px;
  transition: 0.3s;
}
.Header_login_signUp__5X7tk li.Header_selected___0lse {
  background-color: #17a8e5;
  border-radius: 2px;
}
.Header_login_signUp__5X7tk li:hover {
  transition: 0.3s;
  background-color: #17a8e5;
  border-radius: 2px;
  color: #fff;
}
.Header_boqVersionBox__IpUHx {
  border: 1px solid #b0b0b0;
  padding: 8px;
  margin-right: 10px;
  width: 100px;
  text-align: center;
  /* background-color: #f3fcff; */
  /* border-radius: 5px; */
}
.Header_roomName__8E1Hs {
  width: -moz-fit-content;
  width: fit-content;
  /* height: 50px; */
  color: #000000;
  font-size: 12px;
  /* border-left: 0.5px solid #b0b0b0; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-left: 5px;
  font-weight: 600;
  text-transform: capitalize;
  height: 15px;
}
.Header_incoId__yNVQv{width: -moz-fit-content;width: fit-content;
  /* height: 50px; */
  color: #666666;
  font-size: 10px;
  /* border-left: 0.5px solid #b0b0b0; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-left: 5px;
  height: 15px;
  font-weight: 400;}
.Header_labels__fge6s {
  width: -moz-fit-content;
  width: fit-content;
  color: #000000;
  font-size: 10px;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-left: 5px;
  font-weight: 600;
  background: #DAF4FF;
  border-radius: 2px;
}

.Header_cloudSync__yea57 {
  background-color: #f3fcff;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-right: 10px;
}
.Header_syncStatus__EnW0s{
  font-size: 12px;
  color: #666666;
  margin-right: 15px;
  font-style: italic;
}
.Header_errorList__5ipVB{height: 35px;width: 35px;display: flex;align-items: center;justify-content: center;background-color: #FFD2D2;border-radius: 2px;cursor: pointer;margin-right: 10px;} 
.Header_errorListDis__OGCZr{height: 35px;width: 35px;display: flex;align-items: center;justify-content: center;background-color: #E5E5E5;border-radius: 2px;cursor: not-allowed;margin-right: 10px;} 
.DownloadLoader_loaderBackground__NBilD{
    width: 200px;
    height: 150px;
    background: #fff;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px;
}
.DownloadLoader_loaderBackground__NBilD h1{padding: 10px;width: 150px;text-align: center;}
.DownloadLoader_overlayLoader__4qQTl {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(87, 84, 84, 0.7);
  z-index: 25;
  flex-direction: column;
  top: 0;
  left: 0;
}
.Loader_loaderBackground__jp_gX{
    width: 100px;
    height: 100px;
    background: #fff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* components/Maintenance.module.css */
.Maintenance_maintenanceContainer__e6i2P {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #f0f0f0;
    text-align: center;
}

.Maintenance_content__nsFz6 {
    max-width: 600px;
    width: 500px;
    padding: 20px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Maintenance_icon__k7soi{
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}

.Maintenance_label1__Hndg_{
    font-size: 24px;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.ReLogin_successWrapper__LIX9J {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: 0;
  left: 0;
  display: flex;
  z-index: 12;
}
.ReLogin_boundary__xStBr {
  border-radius: 2px;
  background-color: #fff;
  width: 332px;
  height: 220px;
  margin: auto;
  color: aliceblue;
  position: relative;
  /* padding: 30px 0 0; */
  display: flex;
  flex-direction: column;
  border: #17a8e5 1px solid;
}
.ReLogin_boundary__xStBr .ReLogin_cross__Bup0w {
  position: absolute;
  color: #17a8e5;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #17a8e5 1px solid;
}
.ReLogin_boundary__xStBr .ReLogin_cross__Bup0w:hover {
  background-color: #17a8e5;
  color: #fff;
}
.ReLogin_upperBox___icL9 {
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.ReLogin_upperBox___icL9 .ReLogin_message__hRYBY {
  color: #222222;
  font-size: 15px;
  padding: 10px 15px;
  text-align: center;
}
.ReLogin_lowerBox__pWFuR {
  /* width: 330px; */
  /* height: 62px; */
  margin: 16px 0 0;
  padding: 16px 40px;
  /* object-fit: contain; */
  background-color: #daf4ff;
  /* display: flex; */
  /* gap: 15px; */
  border-radius: 0px 0px 2px 2px;
}
.ReLogin_lowerBox__pWFuR .ReLogin_accept__pHjyy {
  width: 100%;
  /* height: 30px; */
  padding: 7px 49px;
  border-radius: 2px;
  background-color: #17a8e5;
  color: #fff;
  cursor: pointer;
}
.ReLogin_lowerBox__pWFuR .ReLogin_accept__pHjyy:hover {
  background-color: #53c7f7;
}

