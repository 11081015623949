/* components/Maintenance.module.css */
.maintenanceContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #f0f0f0;
    text-align: center;
}

.content {
    max-width: 600px;
    width: 500px;
    padding: 20px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.icon{
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}

.label1{
    font-size: 24px;
    margin-bottom: 10px;
    text-transform: capitalize;
}
