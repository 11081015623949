.loaderBackground{
    width: 200px;
    height: 150px;
    background: #fff;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px;
}
.loaderBackground h1{padding: 10px;width: 150px;text-align: center;}
.overlayLoader {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(87, 84, 84, 0.7);
  z-index: 25;
  flex-direction: column;
  top: 0;
  left: 0;
}