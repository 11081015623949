.dropDown {
  min-width: 125px;
  height: 100%;
}
.dropDown::after {
  top: 18px;
}
.dropDown .dropdownchnRbox {
  padding-left: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  text-transform: capitalize;
}

.changEromin {
  box-shadow: 0 5px 15px 0 rgba(119, 119, 119, 0.25);
  border-radius: 2px;
  overflow: auto;
  position: absolute;
  top: 40px;
  left: -2px;
  display: none;
  max-height: 40vh;
  height: fit-content;
}
.dropDown.active .changEromin {
  display: block;
}
.changRoomtilesModular:hover {
  background: #daf4ff;
}
.changRoomtilesModular {
  padding: 10px;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  width: 150px;
  gap: 16px;
  background: #fff;
  border-bottom: 1px solid #b0b0b0;
  justify-content: space-between;
  min-width: 150px;
}
.changRoomtilesModular.active {
  background: #17a8e5;
  color: #fff;
}
.changRoomtilesModular span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropDownDownArrow {
  content: "";
  pointer-events: none;
  position: absolute;
  right: 10%;
  top: 45%;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23222222' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
    no-repeat;
  width: 12px;
  height: 7px;
}

.dropDownUpArrow {
  content: "";
  pointer-events: none;
  position: absolute;
  right: 10%; /* Adjust as needed */
  top: 45%; /* Adjust as needed */
  background: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5L5 1L9 5' stroke='%23222222' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat;
  width: 12px; /* Adjust as needed */
  height: 6px; /* Adjust as needed */
}

/* version */
.changRoomtilesVersion{
  padding: 10px;
  display: flex;
  align-items: center;
  width: 200px;
  gap: 16px;
  background: #fff;
  border-bottom: 1px solid #b0b0b0;
  justify-content: space-between;
  min-width: 150px;
  color: #000;
  height: 38px;

}
.changRoomtilesVersion:hover {
  background: #daf4ff;
}
.changRoomtilesVersion .innerText{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
}
.changRoomtilesVersion.active {
  color: #17a8e5;
  font-weight: 600;
}
.changRoomtilesVersion span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.boqLink{
  width: 20px;
  height: 20px;
  background-color: #daf4ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.changRoomtilesVersion:hover .boqLink {
  background-color: #fff;
}